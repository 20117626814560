$color-50: #f0fdf4;
$color-100: #dcfce7;
$color-200: #bbf7d0;
$color-300: #86efac;
$color-400: #4ade80;
$color-500: #22c55e;
$color-600: #16a34a;
$color-700: #15803d;
$color-800: #166534;
$color-900: #14532d;
$color-950: #052e16;

$dark-color-1: #faf5ff;
$dark-color-2: #f3e8ff;
$dark-color-3: #e9d5ff;
$dark-color-4: #d8b4fe;
$dark-color-5: #c084fc;
$dark-color-6: #a855f7;
$dark-color-7: #9333ea;
$dark-color-8: #7e22ce;
$dark-color-9: #6b21a8;
$dark-color-10: #581c87;
$dark-color-11: #3b0764;
$dark-color-12: #11011d;