@import '../app.scss';
.portfolio {
    position: relative;

    .progress {
        position: sticky;
        top: 0;
        left: 0;
        padding-top: 50px;
        text-align: center;
        color: $dark-color-11;
        font-size: 36px;
        @include mobile {
            padding-top: calc(100vh - 100px);
            font-size: 24px;
         } 

        .progress-bar {
            height: 2px;
            background-color: $dark-color-9;
            width: 100%; // Added width to make it visible
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;


        .wrapper{
            max-width: 85%;
            height: 100%;
            margin:auto;
            display: flex;
           
            gap:50px ;
            align-items: center;
            justify-content: center;
            @include mobile {
               flex-direction: column;
             }

             .slidecontainer{
                height: calc(100vh - 400px);
              
                display: flex;
                flex-direction: column;
             }

             .slidewrapper{
                
                
             }
            
      
        .ImageContainer{
            flex: 1;
            height: 50%;
            @include mobile {
               width: 100%;
               max-height: 300px;
             }
       
        img {
           width: 100%;
           height:100%;
            object-fit: cover;
            @include mobile {
                object-fit: contain;
             }
        }
 }
        .textContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap:30px;
            @include mobile {
               transform: none !important;
               align-items: center;
             }


            h2{
                font-size: 72px;
                color: $dark-color-11;
                @include mobile {
                   font-size: 36px;
                  }
            }


            p{
                color: $dark-color-10;
                font-size: 20px;
                @include mobile {
                    font-size: 16px;
                   }
            }
            button{
               border-radius: 10px;
               border:none; 
               background-color: orange;
               padding:10px;
               width:200px;
               cursor: pointer;
            }

        }
    }
    }
}
