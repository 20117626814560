@import "./styles/variables.scss";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "DM Sans";
}

html {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

body {
    background-color:$color-50;
    color: $color-700;
}

a {
    text-decoration: none;
    color: inherit;
}

section {
    height: 100vh;
    scroll-snap-align: center;
}

@mixin mobile {
    @media (max-width: 738px) {
        @content;
    }
}
@mixin tablet {
    @media (max-width: 900px) {
        @content;
    }
}
@mixin desktop {
    @media (max-width: 1200px) {
        @content;
    }
}