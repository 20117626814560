.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    padding-top: 60px;
  }
  
  .modal-content {
    background-color:rgba(187, 247, 208, 0.5);
    border-radius: 30px;
    margin: auto;
    padding: 20px;
    border: 1px solid #86efac;
    width: 80%;
    max-width: 800px;
  }
  
  .close {
    color: #3b0764;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  