.slider-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .slider {
    display: flex;
    transition: transform 1s ease-in-out;
  }
  
  .slide {
    min-width: 100%;
    transition: opacity 1s ease-in-out;
  
    img {
      width: 100%;
      display: block;
    }
  }
  