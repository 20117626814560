@import '../app.scss';

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;

    .bg {
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 400px;
        background: white;
        @include mobile {
            width: 200px;
         }
        
       
    }

    .links {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        
    }

    a {
        font-size: 40px;
        @include mobile {
            font-size: 20px;
         }
    }

    button {
        z-index: 999;
        width: 50px;
        height: 50px; /* Correct the height */
        position: fixed;
        border-radius: 50%;
        top: 25px;
        left: 25px; /* Added left to place the button properly */
        background-color: transparent;
        border: none;
        color: green;
        font-size: 30px;
        cursor: pointer;
     
       
    }
}
